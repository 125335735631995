// let NODE_ENV = 'production';
let NODE_ENV = 'demo';
// let NODE_ENV = 'local';

let Front_URL = "";
// let Server_URL = "";
let baseUrl = "";
let BUSDAddress = "";
let BNBAddress = "";
let QuantumToken = "";
let QUANTUMICO = "";
let StakingContract = "";
let PriceProvider = "";
let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
if (NODE_ENV === "production") {
    // Server_URL = 'https://demoapi.webonjour.com/';
    // Front_URL = 'https://qrr-project-production.pages.dev/';
    Front_URL = 'https://qrr.world/';
    baseUrl = 'http://localhost:2053';
    AdminAddress = "0x358200Ae9648839851652963EE5F78d45efE773b";
    BUSDAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    QuantumToken = "0x8728115dFf885435D2e8fdB55faB200dEAaeA67D";//QRR
    QUANTUMICO = "0xCB982d9f0519Ea6132025C8855F945d8004E2C44";
    StakingContract = "0x337F94424E8efb0FCf2cCeb0b8da487179A2E0BC";
    txUrlAddress = "https://bscscan.com/address/";
    txurl = "https://bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 56;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "QRR"
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://qrr-frontend.pages.dev/';
    // Server_URL = 'http://localhost:2053/';
    baseUrl = 'http://localhost:2053'
    AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
    BUSDAddress = "0xc5971d4FB93bE6fF4913eEf94b5ed4E154147220";
    BNBAddress = "0xbAbc62Aefa185Cd418f44923F0c906D6aa95f663";
    QuantumToken = "0xe88037FBE50933384fCe6024627feEca9E5E6c54";//QRR
    QUANTUMICO = "0xC158859F512fDE75accE7b04423F1705E262D74F";
    PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
    StakingContract = "0xAB4aC95905900Dd5CB16cF6e79Bb093185DB4201";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;

    networkName = "Binance Chain";
    tokenSymbol = "QRR"
}
else {
    // QUANTUMICO = "0xF6D7b966ba31847636958b8Bb300E826328fAa08";
    Front_URL = 'http://localhost:3000/';
    // Server_URL = 'http://localhost:2053/';
    baseUrl = 'http://localhost:2053'
    AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
    BNBAddress = "0xbAbc62Aefa185Cd418f44923F0c906D6aa95f663";

    //Mainnet Address
    // BUSDAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    // QuantumToken = "0x8728115dFf885435D2e8fdB55faB200dEAaeA67D";//QRR
    // QUANTUMICO = "0xCB982d9f0519Ea6132025C8855F945d8004E2C44";
    // StakingContract = "0x337F94424E8efb0FCf2cCeb0b8da487179A2E0BC";
    // txUrlAddress = "https://bscscan.com/address/";
    // txurl = "https://bscscan.com/tx/";


    //testnet Address
    BUSDAddress = "0xc5971d4FB93bE6fF4913eEf94b5ed4E154147220";
    QuantumToken = "0xe88037FBE50933384fCe6024627feEca9E5E6c54";//QRR
    StakingContract = "0xAB4aC95905900Dd5CB16cF6e79Bb093185DB4201";
    // QUANTUMICO = "0xF6D7b966ba31847636958b8Bb300E826328fAa08";//newproxy
    QUANTUMICO = "0xC158859F512fDE75accE7b04423F1705E262D74F";
    PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;

    networkName = "Binance Chain";
    tokenSymbol = "QRR"
}

let key = {
    Front_URL: Front_URL,
    // Server_URL: Server_URL,
    baseUrl: baseUrl,
    AdminAddress: AdminAddress,
    BUSDAddress: BUSDAddress,
    BNBAddress: BNBAddress,
    QuantumToken: QuantumToken,
    QUANTUMICO: QUANTUMICO,
    StakingContract: StakingContract,
    PriceProvider: PriceProvider,
    BNBSYMBOL: BNBSYMBOL,
    toFixedSwap: toFixedSwap,
    toFixedBal: toFixedBal,
    toFixedvalue: toFixedvalue,
    NetworkId: NetworkId,
    toFixed: toFixed,
    networkName: networkName,
    txUrlAddress: txUrlAddress,
    txurl: txurl,
    tokenSymbol: tokenSymbol
};

export default key;
